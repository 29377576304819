@use 'sass:color';

@import url('https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap');

$primary: #55de5c;
$secondary: #c7c402;
$gray-100: #e8e8e8;
$gray-200: #d1d1d1;
$gray-300: #bbbbbb;
$gray-400: #a4a4a4;
$gray-500: #8d8d8d;
$gray-600: #717171;
$gray-700: #555555;
$gray-800: #383838;
$gray-900: #1c1c1c;
$dark: $gray-800;
$black: #090909;
$white: #e8e8e8;

$text-muted: $gray-500;

$body-bg: $black;
$body-color: $white;

$link-decoration: underline;
$link-color: $gray-500;

$input-font-weight: 400;
$input-color: $primary;
$input-bg: $black;
$input-border-color: $primary;
$input-focus-border-color: color.adjust($primary, $lightness: -5%, $space: hsl);
$input-btn-focus-box-shadow: 0 0 15px 0.2em
  rgba($input-focus-border-color, 0.25);

$form-select-focus-box-shadow: 0 0 0 0.2rem rgba($secondary, 0.5);
$form-select-indicator-padding: 2.5rem;
$form-select-bg-size: 12px 9px;
$form-select-bg: $gray-900;
$form-select-border-width: 0px;
$form-select-color: $gray-300;
$form-select-padding-y-sm: 0.45rem;

$component-active-bg: $secondary;
$custom-select-color: $gray-300;
$custom-select-bg: $gray-900;
$custom-select-border-width: 0;
$input-btn-padding-x-sm: 0.9rem;
$input-btn-padding-y-sm: 0.4rem;

$input-padding-x: 1.3rem;
$input-padding-y: 0.8rem;

$font-family-sans-serif: 'Fira Mono', monospace;
$font-size-base: 0.85rem;
$input-font-size: 1.4rem;

$input-font-weight: 400;
$headings-font-weight: 400;
$display1-weight: 400;
$display2-weight: 400;
$display3-weight: 400;
$display4-weight: 400;

$border-radius: 0px;

$badge-font-size: 75%;
$badge-font-weight: 300;
$badge-pill-padding-x: 1.5em;
$badge-padding-y: 0.75em;

$enable-rounded: false;
$enable-responsive-font-sizes: true;

//https://stackoverflow.com/questions/65170317/sass-scss-bootstrap-5-color-functions-not-working-well
$min-contrast-ratio: 3;

@import '~bootstrap/scss/bootstrap';

.font-letter-spacing-tight {
  letter-spacing: -0.05rem;
}

input[type='search'] {
  letter-spacing: -0.05em;
}

a {
  text-decoration: underline !important;
}

.form-control-secondary {
  color: $secondary;
  border-color: $secondary;

  &:focus {
    color: $secondary;
    border-color: $secondary;
    box-shadow: 0 0 15px 0.2em rgba($secondary, 0.35);
  }
}

.text-dark-2 {
  color: $gray-600;
}

.navbar-toggler-icon {
  background-size: 75% 75%;
}

// For loading indicator svg
.stroke-primary {
  stroke: $primary;
}

.border-width-2 {
  border-width: 2px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('~images/refine_icon.svg');
}
